import React from "react"
import OptimizedImage from "../../utils/optimizedImage"
import { SpeakerContainer, Content, Eyebrow, Heading, Text } from "./styles/PodcastSpeaker.styled"

const Speaker = ({ indiv }) => {
  return (
    <SpeakerContainer>
      {indiv?.headshot && <OptimizedImage image={indiv?.headshot?.gatsbyImageData} src={indiv?.headshot?.url} alt={indiv?.fullName || "speaker image"}/>}
      <Content>
        <Eyebrow>Guest Speaker</Eyebrow>
        {indiv?.fullName && <Heading>{indiv?.fullName}</Heading>}
        <div>
          {indiv?.role && <Text>{indiv?.role}</Text>}
          {indiv?.company?.name && <Text>{indiv?.company?.name}</Text>}
        </div>
      </Content>
    </SpeakerContainer>
  )
}

export default Speaker
