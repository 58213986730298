import styled from "styled-components"
import { font } from "../../../atoms/typography"
import { color } from "../../../atoms/colors"

export const SpeakerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 578px) {
    flex-direction: row;
  }
  .gatsby-image-wrapper {
    width: 120px;
    height: 120px;
    @media (max-width: 576px) {
      width: 100px;
      height: 100px;
    }
  }
  img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    @media (max-width: 576px) {
      width: 100px;
      height: 100px;
    }
  }
`

export const Content = styled.div`
  margin: 24px 0 0;
  @media (min-width: 578px) {
    margin: auto 15px;
  }
  display: grid;
  gap: 4px;
`

export const Eyebrow = styled.p`
  ${font('kicker', 'reg', '900')}
  color: ${color.primary[800]};
`

export const Heading = styled.p`
  ${font('text', 'lg', '700')}
  color: ${color.common.black};
`

export const Text = styled.p`
  ${font('text', 'reg', '400')}
  color: ${color.grey[600]};
`
