import React from "react";
import { PodcastPlayWrap } from "./styles/PodcastDetail.styled";

const PodcastPlayer = ({ podcastEmbed }) => {

  const iframeRegExp = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g
  const heightRegExp = /height="(.*?)"/g

  const setHeightHtml = podcastEmbed.replace(heightRegExp, `height="240"`)
  const match = podcastEmbed?.match(iframeRegExp)

  if (match && setHeightHtml) {
    return (
      <PodcastPlayWrap>
        <div dangerouslySetInnerHTML={{__html: setHeightHtml}} />
      </PodcastPlayWrap>
    )
  } else {
    return null
  }
}

export default PodcastPlayer
