import styled, { css } from "styled-components";
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const PodcastPlayWrap = styled.div`
  max-width: 506px;
  padding: 24px 0;
  margin: 0 auto;
  @media (min-width: 577px) {
    max-width: 770px;
    padding: 36px 0;
  }
  @media (min-width: 993px) {
    max-width: 970px;
    padding: 48px 0;
  }
`

export const DetailContent = styled.div`
  padding: 0 30px;
`

export const PodcastWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  padding: 20px 0 48px;
  max-width: 506px;
  @media (min-width: 577px) {
    grid-row-gap: 64px;
    padding: 36px 0 72px;
    max-width: 770px;
  }
  @media (min-width: 993px) {
    padding: 48px 0 96px;
    max-width: 1170px;
    grid-template-columns: 57fr 31fr;
    column-gap: 12%;
  }
`

export const Switcher = styled.div`
  display: grid;
  gap: 40px;
  align-self: start;
`

export const NavWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color.grey[300]};
  display: flex;
  gap: 32px;
  height: fit-content;
`

export const Tab = styled.span`
  ${font('text', 'reg', '700')}
  color: ${color.grey[500]};
  padding: 16px 0;
  margin-bottom: -1px;
  cursor: pointer;
  ${props => props && props?.active && css`
    color: ${color.common.black};
    border-bottom: 4px solid ${color.common.workgridBlue};
  `}
`

export const Content = styled.div`
  ${font('text', 'reg', '400')}
  color: ${color.grey[600]};
`

export const About = styled.div`
  display: grid;
  gap: 32px;
`
