import React from "react";
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo";
import { graphql } from "gatsby";
import PodcastHero from "../components/Podcast/PodcastHero";
import PodcastPlayer from "../components/Podcast/PodcastPlayer";
import { DetailContent } from "../components/Podcast/styles/PodcastDetail.styled";
import PodcastContent from "../components/Podcast/PodcastContent";
import CardDeck from "../components/CardDeck/CardDeck";
import ConversionPanel from "../components/ConversionPanel/ConversionPanel";

const PodcastDetailTemplate = ({ data }) => {
  const podcast = data?.contentfulResource
  const seo = podcast?.pageSeo
  const podcastHtml = podcast?.podcastEmbed?.podcastEmbed
  const related = podcast?.relatedPosts
  const episodeNum = podcast?.podcastEpisodeNumber
  const conversionPanel = data?.contentfulComponentConversionPanel
  
  return(
    <Layout>
      <Seo 
        title={seo?.seoTitle || podcast?.title}
        description={seo?.seoDescription || podcast?.description?.description}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || podcast?.featuredImage?.file?.url}
        imageAlt={seo?.graphImage?.description || seo?.seoTitle || podcast?.title}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <PodcastHero title={podcast?.title} epNum={episodeNum} publishDate={podcast?.publishDate} speakers={podcast?.speakers} featuredImage={podcast?.featuredImage} />
      <DetailContent>
        <PodcastPlayer podcastEmbed={podcastHtml} />
        <PodcastContent body={podcast?.body} speakers={podcast?.speakers} speakersBody={podcast?.speakersBody} slug={podcast?.slug}/>
      </DetailContent>
      {related && 
        <CardDeck
          backgroundColor="grey"
          heading="Related Resources"
          kicker="More from Workgrid"
          headingSize="H2"
          cards={related}
        />
      }
      {conversionPanel &&
        <ConversionPanel component={conversionPanel} />
      }
    </Layout>
  )
}

export default PodcastDetailTemplate

export const PodcastTemplateQuery = graphql`
  query PodcastTemplateQuery($id: String) {
    contentfulResource(id: { eq: $id }) {
      id
      title
      slug
      podcastEpisodeNumber
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
        article
      }
      publishDate
      externalUrl
      category {
        title
        backgroundColor
      }
      featuredImage {
        gatsbyImageData
        file {
          url
        }
      }
      podcastEmbed {
        podcastEmbed
      }
      description {
        description
      }
      body {
        raw
      }
      videoResource {
        id
        caption {
          raw
        }
        alignCaption
        contentful_id
        autoplay
        title
        videoUrl
        thumbnail {
          gatsbyImageData
          file {
            url
          }
        }
        videoFile {
          title
          description
          gatsbyImageData
          file {
            url
          }
        }
        internal {
          type
        }
      }
      speakers {
        fullName
        headshot {
          gatsbyImageData
          url
        }
        role
        company {
          name
        }
      }
      speakersBody {
        speakersBody
      }
      relatedPosts {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          form {
            marketoFormId
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(id: {eq: "2e806e57-62b8-548c-a015-eb01ab7d265e"}) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      noTopPadding
      noBottomPadding
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
      reference {
        ... on ContentfulComponentTrustbar {
          __typename
          id
          heading
          companies {
            id
            name
            logo {
              gatsbyImageData
              file {
                url
              }
            }
          }
        }
        ... on ContentfulComponentTestimonial {
          __typename
          id
          author {
            id
            fullName
            role
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
            company {
              id
              name
            }
          }
          quote {
            quote
          }
        }
        ... on ContentfulComponentImage {
          __typename
          id
          primaryImage {
            file {
              url
            }
            description
          }
        }
      }
    }
  }
`
