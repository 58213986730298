import React, { useState } from "react";
import { color } from "../../atoms/colors";
import { switchbackResolveRichText } from "../../utils/resolve-rich-text/switchbackResolveRichText";
import MktoForm from "../Form/MktoForm";
import { Content, PodcastWrapper, Switcher, NavWrap, Tab, About } from "./styles/PodcastDetail.styled";
import { BlogSocialShareRow } from "../Blog/BlogDetail/BlogSocialShareRow";
import onKeyDown from "../../utils/onKeyDown"
import Speaker from "./PodcastGuest";

const ActiveSection = ({ activeTab, body, speaker, speakersBody}) => {
  switch (activeTab) {
    case "overview" :
      return switchbackResolveRichText(body, 'dark-check')
    case "speaker" :
      return (
        <About>
          <Speaker indiv={speaker} />
          {speakersBody?.speakersBody}
        </About>
      )
    default :
      return switchbackResolveRichText(body, 'dark-check')
  }
}

const PodcastContent = ({ body, speakers, speakersBody, slug }) => {
  const speaker = speakers && speakers[0]
  const [activeTab, setActiveTab] = useState("overview")

  return (
    <PodcastWrapper>
      <Switcher>
        <NavWrap>
          <Tab 
            onClick={() => setActiveTab("overview")}
            role="button"
            tabIndex={0}
            onKeyDown={e => onKeyDown(e, () => setActiveTab("overview"), "Enter")}
            aria-label="Episode Overview"
            aria-current={activeTab === "overview" ? true : false}
            active={activeTab === "overview"}
          >
            Episode Overview
          </Tab>
          {speaker && 
            <Tab 
              onClick={() => setActiveTab("speaker")}
              role="button"
              tabIndex={0}
              onKeyDown={e => onKeyDown(e, () => setActiveTab("speaker"), "Enter")}
              aria-label="About the Guest"
              aria-current={activeTab === "speaker" ? true : false}
              active={activeTab === "speaker"}
            >
              About the Guest
            </Tab>
          }
        </NavWrap>
        <Content>
          <ActiveSection activeTab={activeTab} body={body} speakersBody={speakersBody} speaker={speaker}/>
        </Content>
      </Switcher>
      <div>
        <MktoForm formId={1828} component={{type: "card", internalName: "Podcast Detail"}}/>
        <BlogSocialShareRow className="desktop" slug={slug} iconColor="blue" backgroundColor={color.grey[100]} />
      </div>
    </PodcastWrapper>
  )
}

export default PodcastContent
